










import { Component, Prop, Vue } from "vue-property-decorator";
import DefaultPageTemplate from "@/components/templates/DefaultPageTemplate.vue";
import OrderList from "@/components/organisms/order/OrderList.vue";


@Component({
  components: {
    DefaultPageTemplate,
    OrderList,
  },
})
export default class OrderPage extends Vue {
  @Prop() orderGuid?: string;
}
