







import { OrderOption } from "@/graphql/client";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import OrderOptionCard from "@/components/atoms/order/OrderOptionCard.vue";
import { Component, ModelSync, Mixins } from "vue-property-decorator";

@Component({ components: { OrderOptionCard } })
export default class OrderOptionCards extends Mixins(MixinFormatter) {
  @ModelSync("options", "updateOptions", { type: Array, required: true })
  readonly optionsValue!: OrderOption[];
  //---------------------------
  // data
  //---------------------------
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
}
