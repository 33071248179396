
import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";


@Component
export default class MixinOrderCommunicationMessageDialogValidator extends Vue {
  @Validations()
  validations = {
    item: {
      //件名
      subject: { required },
      //連絡メッセージ
      message: { required },
    },
  };
  //---------------------------
  // methods
  //---------------------------
  /**
   * 件名の入力エラーメッセージを返します.
   */
  get subjectErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.subject?.$dirty) return errors;
    !this.$v.item.subject?.required && errors.push("件名を入力してください.");
    return errors;
  }
  /**
   * 連絡メッセージの入力エラーメッセージを返します.
   */
  get messageErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.message?.$dirty) return errors;
    !this.$v.item.message?.required && errors.push("連絡メッセージを入力してください.");
    return errors;
  }
}
