
























































































































import { Order } from "@/graphql/client";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import appService from "@/service/appService";
import typeService from "@/service/typeService";
import { Component, ModelSync, Mixins } from "vue-property-decorator";

@Component
export default class PaymentCard extends Mixins(MixinFormatter) {
  @ModelSync("order", "updateOrder", { type: Object, required: true })
  readonly orderValue!: Order;
  //---------------------------
  // data
  //---------------------------
  isProgressing = false;
  prefectures = appService.prefectures;
  paymentMethods = typeService.paymentMethodTypes;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
}
