






























































import { Order } from "@/graphql/client";
import { Component, Emit, Vue } from "vue-property-decorator";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import parse from "csv-parse/lib/sync";
import iconv from "iconv-lite";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class NavFileUploadDialog extends Vue {
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  records: Order[] = [];
  file = null;
  headers = [
    {
      text: "注文コード",
      value: "code",
      width: "15%",
    },
    {
      text: "名称",
      value: "name",
    },
  ];
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  get recordSize(): number {
    return this.records ? this.records.length : 0;
  }
  //---------------------------
  // methods
  //---------------------------
  /**
   * ダイアログを表示します.
   */
  public open(): void {
    this.records = [];
    this.file = null;
    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.records = [];
    this.file = null;
    this.isProgressing = false;
    this.isOpen = false;
  }

  async onNavFilePicked(file: File): Promise<void> {
    this.isProgressing = true;
    if (file !== undefined && file !== null) {
      try {
        const csv = await this.readAsBinaryString(file);
        const buff = Buffer.from(csv, "binary");
        const utfCsv = iconv.decode(buff, "Shift_JIS");
        this.records = this.parseCSV(utfCsv);
        this.isProgressing = false;
      } catch (e) {
        this.isProgressing = false;
        console.log(e);
      }
    } else {
      // ignore
      this.records = [];
      this.isProgressing = false;
    }
  }
  readAsBinaryString(file: File): Promise<string> {
    return new Promise<string>((resolve, reject): void => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        fileReader.result ? resolve(fileReader.result.toString()) : reject;
      };
      fileReader.onerror = reject;
      fileReader.readAsBinaryString(file);
    });
  }
  parseCSV(input: string): Order[] {
    const records = parse(input, {
      columns: true,
      skip_empty_lines: true,
    });
    return records;
  }

  /**
   * 一括更新します.
   */
  private saveItems(): void {
    this.isProgressing = true;
    console.log(this.records);
    //TODO bluk update
    setTimeout(() => {
      this.notifySuccess();
    }, 3000);
  }

  /**
   * 処理成功
   */
  @Emit("onSuccess")
  public notifySuccess(): string {
    return "uploaded";
  }
}
