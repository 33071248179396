import { DeliverdCoupon, Member, MemberFilter, MemberOrderByInput, Sort } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * メンバーに関する機能を提供します.
 */
class MemberService {
  /**
   * 初期状態のメンバー
   */
  public get defaultMember(): Member {
    return {
      guid: "",
      id: "",
      name: "",
      nameKana: "",
      email: "",
      sex: "",
      birthday: "",
      postalCode: "",
      prefcture: "",
      address1: "",
      address2: "",
      address3: "",
      phone: "",
      cellPhone: "",
      memo: "",
      accountNumberBC: "",
      isAvailable: true,
      createdAt: "",
      updatedAt: "",
      hasSubscriptionToMailzine: false,
    } as Member;
  }

  /**
   * 初期状態のメンバー
   */
  public get defaultDeliverdCoupon(): DeliverdCoupon {
    return {
      guid: "",
      coupon: undefined,
      member: undefined,
      couponTitle: "",
      couponAmount: 0,
      couponDdescription: "",
      expirationDate: "",
      createdAt: "",
      updatedAt: "",
    } as DeliverdCoupon;
  }

  /**
   * すべてのメンバーを取得して返します.
   * @returns すべてのメンバー
   */
  public async allMembers() {
    const response = await sdk.members();
    if (response?.members) {
      return response.members as Member[];
    }
    return [] as Member[];
  }
  /**
   * @param condition 検索条件
   * @returns 検索結果
   */
  public async searchMembers(
    filter: MemberFilter | undefined = undefined,
    skip: number | undefined = undefined,
    take: number | undefined = undefined
  ) {
    const orderBY: MemberOrderByInput = {
      createdAt: Sort.Desc,
    };
    const response = await sdk.members({
      filter: filter,
      skip: skip,
      take: take,
      orderBy: orderBY,
    });
    return response.members as Member[];
  }
  /**
   * @param condition 検索条件
   * @returns 検索結果
   */
  public async countMembers(
    filter: MemberFilter | undefined = undefined,
  ) {
    const orderBY: MemberOrderByInput = {
      createdAt: Sort.Desc,
    };
    const response = await sdk.countMembers({
      filter: filter,
    });
    return response.countMembers as number;
  }

  /**
   * 指定したGUIDに一致するメンバーを取得して返します.
   * @param guid メンバーのGUID
   * @returns メンバー
   */
  public async getMember(guid: string) {
    const response = await sdk.member({
      memberKey: {
        guid: guid,
      },
    });
    return response.member as Member;
  }

  /**
   * 指定したIDに一致するメンバーを取得して返します.
   * @param id メンバーのID
   * @returns メンバー
   */
  public async getMemberById(id: string) {
    const response = await sdk.member({
      memberKey: {
        id: id,
      },
    });
    return response.member as Member;
  }

  /**
   * 指定したメールアドレスに一致するメンバーを取得して返します.
   * @param email メンバーのメールアドレス
   * @returns メンバー
   */
  public async getMemberByEmail(email: string) {
    const response = await sdk.member({
      memberKey: {
        email: email,
      },
    });
    return response.member as Member;
  }

  /**
   * メンバーを登録します.（IDは自動採番）
   * @param input メンバーの登録情報
   * @returns 登録完了後のメンバー
   */
  public async createMember(input: Member) {
    if (!input.name) {
      throw new Error("name is required.");
    }
    try {
      const response = await sdk.createMember({
        createMemberInput: {
          name: input.name,
          email: input.email,
        },
      });

      if (response?.createMember) {
        return response.createMember;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * メンバーを更新します.
   * @param input メンバーの更新情報
   * @returns 更新完了後のメンバー
   */
  public async updateMember(input: Member) {
    try {
      if (!input.guid) {
        throw new Error("guid is required.");
      }
      const response = await sdk.updateMember({
        updateMemberInput: {
          guid: input.guid,
          name: input.name,
          nameKana: input.nameKana,
          email: input.email,
          sex: input.sex,
          birthday: input.birthday,
          postalCode: input.postalCode,
          prefecture: input.prefecture,
          address1: input.address1,
          address2: input.address2,
          address3: input.address3,
          phone: input.phone,
          cellPhone: input.cellPhone,
          memo: input.memo,
          accountNumberBC: input.accountNumberBC,
          hasSubscriptionToMailzine: input.hasSubscriptionToMailzine,
          isAvailable: input.isAvailable,
        },
      });

      if (response?.updateMember) {
        return response.updateMember;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * メンバーを物理削除します.
   * @param input メンバーの削除情報
   * @returns 削除完了後のメンバー
   */
  public async deleteMember(input: Member) {
    try {
      if (!input.guid) {
        throw new Error("guid is required.");
      }
      const response = await sdk.deleteMember({
        deleteMemberInput: {
          guid: input.guid,
        },
      });

      if (response?.deleteMember) {
        return response.deleteMember;
      }
    } catch (err) {
      console.log(err);
    }
  }


  /**
   * メンバーになりかわってマイページ画面を表示します
   */
  public async showMypageAsMember(member: Member) {
    console.log("showMypageAsMember");
    const url: string = process.env.VUE_APP_EC_MYPAGE_URL || "";
    const email: string = member.email ? member.email : "";

    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'x-giftnavi-email': email
      },
    })
    const blob = await res.blob()
    console.log(blob)
    window.open(window.URL.createObjectURL(blob), "_blank")?.focus()
  }
}

export default new MemberService();
