







































































































































































































import { CommunicationMessage, Order } from "@/graphql/client";
import { Component, Emit, Mixins, Ref } from "vue-property-decorator";
import orderService from "@/service/orderService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import ImagePicker from "@/components/molecules/ImagePicker.vue";
import Notification from "@/components/molecules/Notification.vue";
import { NotificationState } from "@/store/modules/notification";
import communicationMessageService from "@/service/communicationMessageService";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import MixinValidator from "@/components/mixins/order/MixinOrderCommunicationMessageDialogValidator.vue";
import moment from "moment";

@Component({
  components: {
    ProgressLinear,
    ImagePicker,
    Notification,
  },
})
export default class OrderCommunicationMessageDialog extends Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly messageForm!: HTMLFormElement;
  @Ref() readonly imagePicker01!: ImagePicker;
  @Ref() readonly imagePicker02!: ImagePicker;
  @Ref() readonly imagePicker03!: ImagePicker;
  @Ref() readonly imagePicker04!: ImagePicker;
  @Ref() readonly imagePicker05!: ImagePicker;
  //---------------------------
  // data
  //---------------------------
  snackbar = false;
  isOpen = false;
  isProgressing = false;
  isNotifyProgressing = false;
  order: Order = orderService.defaultOrder;
  item: CommunicationMessage = orderService.defaultCommunicationMessage;
  messages: CommunicationMessage[] = [];
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  get type(): string {
    return NotificationState.type;
  }
  get message(): string {
    return NotificationState.message;
  }
  get visibility(): boolean {
    return NotificationState.visibility;
  }
  //---------------------------
  // methods
  //---------------------------
  public async fetchData(order: Order) {
    this.order = order;

    //メッセージ取得
    if (this.order.guid) {
      let messages = await communicationMessageService.selectCommunicationMessagesByOrderGuid(this.order.guid);
      if ( messages && messages.length > 0 ) {
        this.messages = messages;
      } else {
        this.messages = [];
      }
    }

    //初期化
    this.item = communicationMessageService.defaultCommunicationMessage;
    this.item.order = this.order;


    if (this.imagePicker01) this.imagePicker01.reset();
    if (this.imagePicker02) this.imagePicker02.reset();
    if (this.imagePicker03) this.imagePicker03.reset();
    if (this.imagePicker04) this.imagePicker04.reset();
    if (this.imagePicker05) this.imagePicker05.reset();

    this.$v.$reset();
  }
  /**
   * 日付を「YYYY/MM/DD HH:00」形式の文字列にして返します.
   */
  public formatDateTime(value: string): string {
    return value ? moment(value).format("YYYY-MM-DD HH:00") : "";
  }
  /**
   * 既読メッセージの場合は「primary」を、未読の場合は「pink」を返します.
   */
  public getIsReadColor(message: CommunicationMessage): string {
    return message.readDate ? "primary" : "pink";
  }

  /**
   * ダイアログを表示します.
   */
  public async open(order: Order): Promise<void> {
    await this.fetchData(order);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  async postMessage(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      if (this.item.guid) {
        //update
        await communicationMessageService
          .updateCommunicationMessage(this.item)
          .then((result) => {
            this.isProgressing = false;
            //初期化
            this.fetchData(this.order);
            this.notifyUpdateSuccess(result as CommunicationMessage);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("連絡メッセージを更新できませんでした.");
          });
      } else {
        //create
        await communicationMessageService
          .createCommunicationMessage(this.item)
          .then((result) => {
            this.isProgressing = false;
            //初期化
            this.fetchData(this.order);
            this.notifyCreateSuccess(result as CommunicationMessage);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("連絡メッセージを登録できませんでした.");
          });
      }
    }
  }

  resetMessageForm(): void {
    this.fetchData(this.order);
  }

  /**
   * 通知メールを送信します
   */
  async notifyCommunicationMessage(item: CommunicationMessage): Promise<void> {
    if ( confirm("メッセージカードデザインのお知らせメールを送信しますか？") ) {
      this.isNotifyProgressing = true;
      await communicationMessageService
        .notifyMessageCardDesingCommunicationMessage(item)
        .then((result) => {
          this.isNotifyProgressing = false;
          //送信完了
          this.fetchData(this.order);
          this.notifySendNotifySuccess(result as CommunicationMessage);
        })
        .catch((err) => {
          console.error(err);
          this.notifyError("メールを送信できませんでした.");
        });
    }
  }

  async deletCommunicationMessage(item: CommunicationMessage) {
    if ( confirm("この連絡メッセージを削除しますか？") ) {
      this.isNotifyProgressing = true;
      await communicationMessageService
        .deleteCommunicationMessage(item)
        .then((result) => {
          this.isNotifyProgressing = false;
          this.fetchData(this.order);
          this.notifyDeleteSuccess(result as CommunicationMessage);
        })
        .catch((err) => {
          console.error(err);
          this.notifyError("連絡メッセージを削除できませんでした.");
        });
    }
  }
    


  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: CommunicationMessage): CommunicationMessage {
    return created;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: CommunicationMessage): CommunicationMessage {
    return updated;
  }
  /**
   * 削除成功
   */
  @Emit("onDeleteSuccess")
  public notifyDeleteSuccess(deleted: CommunicationMessage): CommunicationMessage {
    return deleted;
  }
  /**
   * メッセージ送信成功
   */
  @Emit("onSendNotifySuccess")
  public notifySendNotifySuccess(message: CommunicationMessage): CommunicationMessage {
    return message;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}
