































































import { OrderOption, OrderOptionPropertyValue, PropertyType } from "@/graphql/client";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import { Component, Prop, Mixins } from "vue-property-decorator";

@Component
export default class OrderOptionCard extends Mixins(MixinFormatter) {
  @Prop({ type: Object, required: true })
  option!: OrderOption;
  //---------------------------
  // data
  //---------------------------
  reveal = false;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  public showOptionImage(imageName:string, imageData:string) {
    //画像を別ウィンドウで表示
    let win = window.open('about:blank');
    if (win) {
      win.document.title = imageName;
      win.document.write("<h1>" + imageName + "</h1><img src='" + imageData + "' title=" + imageName + " />");
    }
  }
  isImageProperty(prop: OrderOptionPropertyValue) {
    if (prop.propertyType == PropertyType.OriginalMessageCard) return true;
    if (prop.propertyType == PropertyType.MaterialImage) return true;
    return false;
  }
}
