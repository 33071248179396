














































































import { Member } from "@/graphql/client";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import appService from "@/service/appService";
import { Component, ModelSync, Mixins } from "vue-property-decorator";

@Component
export default class MemberCard extends Mixins(MixinFormatter) {
  @ModelSync("member", "updateMember", { type: Object, required: true })
  readonly memberValue!: Member;
  //---------------------------
  // data
  //---------------------------
  isProgressing = false;
  prefectures = appService.prefectures;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
}
