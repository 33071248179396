import { CommunicationMessage, Option, Order, Sort } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * 連絡メッセージ関する機能を提供します.
 */
class CommunicationMessageService {
  /**
   * 初期状態の CommunicationMessage
   */
  public get defaultCommunicationMessage(): CommunicationMessage {
    return {
      guid: "",
      imageUrl01: "",
      imageUrl02: "",
      imageUrl03: "",
      imageUrl04: "",
      imageUrl05: "",
      message: "",
      notifyDate: undefined,
      readDate: undefined,
      senderName: "",
      subject: "",
      createdAt: undefined,
      updatedAt: undefined,
      order: undefined,
    } as CommunicationMessage;
  }

  /**
   * CommunicationMessageを検索します.
   * @param condition 検索条件
   * @returns 検索結果
   */
  public async allCommunicationMessages() {
    const response = await sdk.communicationMessages({
      orderBy: {
        createdAt: Sort.Desc
      }
    });
    if (response?.communicationMessages) {
      return response.communicationMessages as CommunicationMessage[];
    }
    return [] as CommunicationMessage[];
  }

  /**
   * 指定したGUIDに一致するCommunicationMessageを取得します.
   */
  public async getCommunicationMessage(guid: string) {
    const response = await sdk.communicationMessage({
      guid: guid,
    });
    return response.communicationMessage as CommunicationMessage;
  }

  /**
   * 指定したorderGuidに一致するCommunicationMessageを取得して返します.
   * @param orderGuid 
   * @returns CommunicationMessage
   */
   public async selectCommunicationMessagesByOrderGuid(orderGuid: string) {
    const response = await sdk.communicationMessages({
      filter: {
        orderGuid: orderGuid,
      },
      orderBy: {
        createdAt: Sort.Desc
      }
    });
    if (response?.communicationMessages) {
      return response.communicationMessages as CommunicationMessage[];
    }
    return [] as CommunicationMessage[];
  }

  /**
   * CommunicationMessageを登録します.
   * @param input CommunicationMessageの登録情報
   * @returns 登録完了後のCommunicationMessage
   */
  public async createCommunicationMessage(input: CommunicationMessage) {
    if (!input.order?.guid) {
      throw new Error("order.guid is required.");
    }
    try {
      const response = await sdk.createCommunicationMessage({
        input: {
          orderGuid: input.order.guid,
          message: input.message,
          subject: input.subject,
          senderName: input.senderName,
          readDate: input.readDate,
          notifyDate: input.notifyDate,
          imageUrl01: input.imageUrl01,
          imageUrl02: input.imageUrl02,
          imageUrl03: input.imageUrl03,
          imageUrl04: input.imageUrl04,
          imageUrl05: input.imageUrl05,
        },
      });

      if (response?.createCommunicationMessage) {
        return response.createCommunicationMessage;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * CommunicationMessageを更新します.
   * @param input CommunicationMessageの更新情報
   * @returns 更新完了後のCommunicationMessage
   */
  public async updateCommunicationMessage(input: CommunicationMessage) {
    if (!input.order?.guid) {
      throw new Error("order.guid is required.");
    }
    try {
      const response = await sdk.updateCommunicationMessage({
        input: {
          guid: input.guid,
          orderGuid: input.order.guid,
          message: input.message,
          subject: input.subject,
          senderName: input.senderName,
          readDate: input.readDate,
          notifyDate: input.notifyDate,
          imageUrl01: input.imageUrl01,
          imageUrl02: input.imageUrl02,
          imageUrl03: input.imageUrl03,
          imageUrl04: input.imageUrl04,
          imageUrl05: input.imageUrl05,
        },
      });

      if (response?.updateCommunicationMessage) {
        return response.updateCommunicationMessage;
      }
    } catch (err) {
      console.log(err);
    }
  }


  /**
   * CommunicationMessageを削除します.
   * @param input CommunicationMessageの削除情報
   * @returns 削除完了後のCommunicationMessage
   */
   public async deleteCommunicationMessage(input: CommunicationMessage) {
    if (!input?.guid) {
      throw new Error("guid is required.");
    }
    try {
      const response = await sdk.deleteCommunicationMessage({
        input: {
          guid: input.guid,
        },
      });

      if (response?.deleteCommunicationMessage) {
        return response.deleteCommunicationMessage;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * メッセージカードデザインのお知らせを注文者に対してメールで通知します.
   */
  public async notifyMessageCardDesingCommunicationMessage(input: CommunicationMessage) {
    if (!input?.guid) {
      throw new Error("guid is required.");
    }
    try {
      const response = await sdk.notifyMessageCardDesingCommunicationMessage({
        input: {
          guid: input.guid,
        },
      });

      if (response?.notifyMessageCardDesingCommunicationMessage) {
        return response.notifyMessageCardDesingCommunicationMessage;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new CommunicationMessageService();
