import { HandoverMessage, Option, Order, Sort } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * 申し送りメッセージ関する機能を提供します.
 */
class HandoverMessageService {
  /**
   * 初期状態の HandoverMessage
   */
  public get defaultHandoverMessage(): HandoverMessage {
    return {
      guid: "",
      message: "",
      readDate: undefined,
      senderName: "",
      subject: "",
      createdAt: undefined,
      updatedAt: undefined,
      order: undefined,
    } as HandoverMessage;
  }

  /**
   * HandoverMessageを検索します.
   * @param condition 検索条件
   * @returns 検索結果
   */
  public async allHandoverMessages() {
    const response = await sdk.handoverMessages({
      orderBy: {
        createdAt: Sort.Desc
      }
    });
    if (response?.handoverMessages) {
      return response.handoverMessages as HandoverMessage[];
    }
    return [] as HandoverMessage[];
  }

  /**
   * 指定したGUIDに一致するHandoverMessageを取得します.
   */
  public async getHandoverMessage(guid: string) {
    const response = await sdk.handoverMessage({
      guid: guid,
    });
    return response.handoverMessage as HandoverMessage;
  }

  /**
   * 指定したorderGuidに一致するHandoverMessageを取得して返します.
   * @param orderGuid 
   * @returns HandoverMessage
   */
   public async selectHandoverMessagesByOrderGuid(orderGuid: string) {
    const response = await sdk.handoverMessages({
      filter: {
        orderGuid: orderGuid,
      },
      orderBy: {
        createdAt: Sort.Desc
      }
    });
    if (response?.handoverMessages) {
      return response.handoverMessages as HandoverMessage[];
    }
    return [] as HandoverMessage[];
  }

  /**
   * HandoverMessageを登録します.
   * @param input HandoverMessageの登録情報
   * @returns 登録完了後のHandoverMessage
   */
  public async createHandoverMessage(input: HandoverMessage) {
    if (!input.order?.guid) {
      throw new Error("order.guid is required.");
    }
    try {
      const response = await sdk.createHandoverMessage({
        input: {
          orderGuid: input.order.guid,
          message: input.message,
          subject: input.subject,
          senderName: input.senderName,
          readDate: input.readDate,
        },
      });

      if (response?.createHandoverMessage) {
        return response.createHandoverMessage;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * HandoverMessageを更新します.
   * @param input HandoverMessageの更新情報
   * @returns 更新完了後のHandoverMessage
   */
  public async updateHandoverMessage(input: HandoverMessage) {
    if (!input.order?.guid) {
      throw new Error("order.guid is required.");
    }
    try {
      const response = await sdk.updateHandoverMessage({
        input: {
          guid: input.guid,
          orderGuid: input.order.guid,
          message: input.message,
          subject: input.subject,
          senderName: input.senderName,
          readDate: input.readDate,
        },
      });

      if (response?.updateHandoverMessage) {
        return response.updateHandoverMessage;
      }
    } catch (err) {
      console.log(err);
    }
  }


  /**
   * HandoverMessageを削除します.
   * @param input HandoverMessageの削除情報
   * @returns 削除完了後のHandoverMessage
   */
   public async deleteHandoverMessage(input: HandoverMessage) {
    if (!input?.guid) {
      throw new Error("guid is required.");
    }
    try {
      const response = await sdk.deleteHandoverMessage({
        input: {
          guid: input.guid,
        },
      });

      if (response?.deleteHandoverMessage) {
        return response.deleteHandoverMessage;
      }
    } catch (err) {
      console.log(err);
    }
  }

}

export default new HandoverMessageService();
