








































































import { Order } from "@/graphql/client";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import appService from "@/service/appService";
import { Component, ModelSync, Mixins } from "vue-property-decorator";

@Component
export default class SenderCard extends Mixins(MixinFormatter) {
  @ModelSync("order", "updateOrder", { type: Object, required: true })
  readonly orderValue!: Order;
  //---------------------------
  // data
  //---------------------------
  isProgressing = false;
  prefectures = appService.prefectures;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
}
