










































































































































import { HandoverMessage, Order } from "@/graphql/client";
import { Component, Emit, Mixins, Ref, Vue } from "vue-property-decorator";
import orderService from "@/service/orderService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import Notification from "@/components/molecules/Notification.vue";
import { NotificationState } from "@/store/modules/notification";
import moment from "moment";
import handoverMessageService from "@/service/handoverMessageService";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import MixinValidator from "@/components/mixins/order/MixinOrderHandoverMessageDialogValidator.vue";

@Component({
  components: {
    ProgressLinear,
    Notification,
  },
})
export default class OrderHandoverMessageDialog extends Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly messageForm!: HTMLFormElement;
  //---------------------------
  // data
  //---------------------------
  snackbar = false;
  isOpen = false;
  isProgressing = false;
  isNotifyProgressing = false;
  order: Order = orderService.defaultOrder;
  item: HandoverMessage = handoverMessageService.defaultHandoverMessage;
  messages: HandoverMessage[] = [];
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  get type(): string {
    return NotificationState.type;
  }
  get message(): string {
    return NotificationState.message;
  }
  get visibility(): boolean {
    return NotificationState.visibility;
  }
  //---------------------------
  // methods
  //---------------------------
  public async fetchData(order: Order) {
    this.order = order;

    //メッセージ取得
    if (this.order.guid) {
      let messages = await handoverMessageService.selectHandoverMessagesByOrderGuid(this.order.guid);
      if ( messages && messages.length > 0 ) {
        this.messages = messages;
        this.order.handoverMessages = messages;
      } else {
        this.messages = [];
      }
    }


    //初期化
    this.item = handoverMessageService.defaultHandoverMessage;
    this.item.order = this.order;

    this.$v.$reset();
  }

  /**
   * 日付を「YYYY/MM/DD HH:00」形式の文字列にして返します.
   */
  public formatDateTime(value: string): string {
    return value ? moment(value).format("YYYY-MM-DD HH:00") : "";
  }
  /**
   * 既読メッセージの場合は「primary」を、未読の場合は「pink」を返します.
   */
  public getIsReadColor(message: HandoverMessage): string {
    return message.readDate ? "primary" : "pink";
  }
  /**
   * ダイアログを表示します.
   */
  public async open(order: Order): Promise<void> {
    await this.fetchData(order);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  async postMessage(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      if (this.item.guid) {
        //update
        await handoverMessageService
          .updateHandoverMessage(this.item)
          .then(async (result) => {
            this.isProgressing = false;
            //初期化
            await this.fetchData(this.order);
            this.notifyUpdateSuccess(result as HandoverMessage);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("申し送りメッセージを更新できませんでした.");
          });
      } else {
        //create
        await handoverMessageService
          .createHandoverMessage(this.item)
          .then(async (result) => {
            this.isProgressing = false;
            //初期化
            await this.fetchData(this.order);
            this.notifyCreateSuccess(result as HandoverMessage);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("申し送りメッセージを登録できませんでした.");
          });
      }
    }
  }

  async readHandoverMessage(item: HandoverMessage): Promise<void> {
      this.isNotifyProgressing = true;
      item.readDate = new Date();
      await handoverMessageService
        .updateHandoverMessage(item)
        .then(async (result) => {
          this.isNotifyProgressing = false;
          //更新完了
          await this.fetchData(this.order);
          this.notifyReadSuccess(result as HandoverMessage);
        })
        .catch((err) => {
          console.error(err);
          this.notifyError("既読にできませんでした.");
        });
  }

  async deletHandoverMessage(item: HandoverMessage) {
    if ( confirm("この申し送りメッセージを削除しますか？") ) {
      this.isNotifyProgressing = true;
      await handoverMessageService
        .deleteHandoverMessage(item)
        .then(async (result) => {
          this.isNotifyProgressing = false;
          await this.fetchData(this.order);
          this.notifyDeleteSuccess(result as HandoverMessage);
        })
        .catch((err) => {
          console.error(err);
          this.notifyError("申し送りメッセージを削除できませんでした.");
        });
    }
  }

  resetMessageForm(): void {
    this.fetchData(this.order);
  }

  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: HandoverMessage): Order {
    return this.order;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: HandoverMessage): Order {
    return this.order;
  }
  /**
   * 削除成功
   */
  @Emit("onDeleteSuccess")
  public notifyDeleteSuccess(deleted: HandoverMessage): Order {
    return this.order;
  }
  /**
   * メッセージ送信成功
   */
  @Emit("onReadSuccess")
  public notifyReadSuccess(message: HandoverMessage): Order {
    return this.order;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}
